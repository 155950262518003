<template>
  <iframe
    :src="`${source}?providerId=${data.providerId}&memberId=${data.memberId}`"
    frameborder="0"
    height="100%"
    width="100%"
  ></iframe>
</template>

<script>
import { BOOKING_SOURCE } from '../constants/env';

export default {
  name: 'Booking',
  data: () => ({
    source: BOOKING_SOURCE,
  }),
  computed: {
    data() {
      const { id, providerId } = this.$store.getters['patientPortal/currentUser'];
      return {
        providerId: encodeURI(providerId),
        memberId: encodeURI(id),
      };
    },
  },
};
</script>
